.form {
	&-cta-container {
		input[type=text],
		input[type=number],
		input[type=email],
		input[type=password],
		textarea,
		select.form-control {
			max-width: 100%;
			min-width: auto;
			width: 100%;
		}
	}
}

.form-cta-container form.form {
	background-color: white;
	color: #61666b;
	box-shadow: 0px 5px 10px 4px rgba(0,0,0,0.12);
	padding: 25px 25px;
}

.form-cta-container form.form {
	.Form__Element .Form__Element__Caption {
		display: inline-block;
	}

	input {
		border: 2px solid #dad9d6;
		border-radius: none;
	}

	button {
		min-width: 145px;
	}
}
